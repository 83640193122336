.tops-flops-item-card:nth-child(1) {
  background-color: rgba(34, 159, 108, 0.35);

  .change {
    background-color: rgba(34, 159, 108, 1);;
  }
}

.tops-flops-item-card:nth-child(2) {
  background-color: rgba(34, 159, 108, 0.2);

  .change {
    background-color: rgba(34, 159, 108, 0.8);
  }
}

.tops-flops-item-card:nth-child(3) {
  background-color: rgba(34, 159, 108, 0.1);

  .change {
    background-color: rgba(34, 159, 108, 0.65);
  }
}

.tops-flops-item-card:nth-child(4) {
  background-color: rgba(240, 42, 69, 0.1);

  .change {
    background-color: #f02a4533;
  }
}

.tops-flops-item-card:nth-child(5) {
  background-color: rgba(240, 42, 69, 0.2);

  .change {
    background-color: #f02a4533;
  }
}

.tops-flops-item-card:nth-child(6) {
  background-color: rgba(240, 42, 69, 0.35);

  .change {
    background-color: #f02a4533;
  }
}

.tops-flops-item-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  color: white;
  border-radius: 8px;

  .change {
    padding: 4px 12px;
  }

  button {
    background-color: white;
    color: inherit;
    padding: 4px 8px;
    border-radius: 4px;
  }

  button.buy {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
  }

  button.sell {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
  }
}
